@font-face {
    font-family: 'hkgrotesk';
    src: url('../fonts-hkgrotesk/HKGrotesk-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'hkgrotesk-bold';
    src: url('../fonts-hkgrotesk/HKGrotesk-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'hkgrotesk-boldItalic';
    src: url('../fonts-hkgrotesk/HKGrotesk-BoldItalic.otf') format('opentype');
}
