$messageFontColor: #ffffff;
$MessageBoxInfoBackgroundColor: #1976d2;
$MessageBoxFailBackgroundColor: #f44336;
$MessageBoxErrorBackgroundHoverColor: #ee242e;

$messageCancelFontColor: #9a8335;
$MessageBoxCancelBackgroundColor: #e8b70c;
$MessageBoxCancelBackgroundHoverColor: #caa00e;

$messageTimeoutFontColor: #9e592b;
$MessageBoxTimeoutBackgroundColor: #FF8F44;
$MessageBoxTimeoutBackgroundHoverColor: #d47637;


.margin-content {
    margin-top: 20px;

    @media (max-width: 960px) {
        margin-top: 5px;
    }
}

.round, .round img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.order-dialog-container .mat-dialog-container {
    padding: 0;
}

.paid {
    color: $messageFontColor;
    background: $MessageBoxInfoBackgroundColor;
}

.paid-inv {
    background: $messageFontColor;
    color: $MessageBoxInfoBackgroundColor;    
}

.payment-failed {
    color: $messageFontColor;
    background: $MessageBoxFailBackgroundColor;
}

.payment-failed-inv {
    background: $messageFontColor;
    color: $MessageBoxFailBackgroundColor;
}

.payment-cancel {
    color: $messageFontColor;
    background: $MessageBoxCancelBackgroundColor;
}

.payment-cancel-inv {
    background: $messageFontColor;
    color: $MessageBoxCancelBackgroundColor;
}

.payment-timeout {
    color: $messageFontColor;
    background: $MessageBoxTimeoutBackgroundColor;
}

.payment-timeout-inv {
    background: $messageFontColor;
    color: $MessageBoxTimeoutBackgroundColor;
}


.close-img {
    width: 15px;
    height: 16px;
    margin: auto;
}

.message-container {
    color: $messageFontColor;
    text-align: center;
    padding: 43px 1em 9px 1em;

    .message-inner-container {
        border-radius: 4px;
        position: relative;

        .title-img-container {
            display: flex;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            
            width: 70px; 
            height: 70px;

            @media (max-width: 960px) {
                width: 56px; 
                height: 56px;
            }

            .title-img-outer {
                width: 66px;
                height: 66px;
                margin: auto;
                display: flex;
                cursor: pointer;

                @media (max-width: 960px) {
                    width: 52px;
                    height: 52px;
                }    
            }

            .title-close-outer {
                @extend .title-img-outer; 
            }

            .title-close-outer-cancel {
                @extend .title-img-outer; 
            }

            .title-close-outer-timeout {
                @extend .title-img-outer; 
            }
            
            .title-close-outer-error {
                @extend .title-img-outer; 
            }

            .title-close-outer-cancel:hover {
                background: $MessageBoxCancelBackgroundHoverColor !important;
            }

            .title-close-outer-timeout:hover {
                background: $MessageBoxTimeoutBackgroundHoverColor !important;
            }

            .title-close-outer-error:hover {
                background: $MessageBoxErrorBackgroundHoverColor !important;
            }

            .title-img {
                margin: auto;
                font-size: 40px;
                width: 100%;
                height: 100%;
                margin-top: 12px;
                cursor: pointer;

                @media (max-width: 960px) {
                    font-size: 40px;
                    margin-top: 6px;
                }    
            }
        }
    
        .title-container {
            padding: 50px 0px 27px 0px;

            @media (max-width: 960px) {
                padding: 20px 0px 10px 0px;
            }

            .content {
                padding: 5px; 
                font-size: 20px;
                line-height: 27px;
            }

            .title-text-container {
                padding: 0px 20px 0px 20px;
                font-size: 16px;

                &.canel {
                    color: $messageCancelFontColor;
                }

                &.timeout {
                    color: $messageTimeoutFontColor;
                }

                .title-base {
                    font-size: 30px; 
                    line-height: 40px;
                }

                .title {
                    margin-bottom: 20px;

                    @media (max-width: 960px) {
                        font-size: 16px;
                        margin-bottom: 5px;
                    }
                }
            
                .mobile-title {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 43px; 
                    margin-right: 10px;
                }

                .subtitle {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 16px;
                    line-height: 24px;

                    @media (max-width: 960px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

$size: 10px;

.right-hole,
.left-hole {
  height: $size * 2;
  width: $size;
}

.left-hole {
    position: absolute;
    border-bottom-left-radius: $size * 2;
    border-top-left-radius: $size * 2;
    top: -$size;
    right: -1px;
}

.right-hole {
    position: absolute;
    border-bottom-right-radius: $size * 2;
    border-top-right-radius: $size * 2;
    top: -$size;
    left: -1px;
}

.close-container {
    position: absolute;
    display: flex;
    top: 8px;
    left: calc(100% - 42px);
    width: 27px;
    height: 27px;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;
}

.separator {
    margin: 20px 0 20px 0;
    position: relative;
    height: 2px;
    width: 100%;
    background-position: bottom;
    background-size: 9px 2px;
    background-repeat: repeat-x; 

    @media (max-width: 960px) {
        margin: 5px 0 5px 0;
    }
}

.download-button {
    cursor: pointer;
    outline: none;
    padding: 10px 20px 10px 20px;
    color:$messageFontColor;
    background: $MessageBoxInfoBackgroundColor;    
    border: 0;
    border-radius: 40px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;    
}

.title-img-outer:hover,
.download-button:hover {
    background: #135290;;
}

.download-button-logo {
    display: flex;
    width: 40px;
    height: 46px;
    margin-right: 3px;     
}

.download-button-spiner {
    font-size: 41px;
    margin: auto;
}

.separator-paid {
    @extend .separator;
    background-image: linear-gradient(to right, rgba($MessageBoxInfoBackgroundColor, .6) 23%, rgba(255, 255, 255, .6) 0%);
}

.separator-payment-failed {
    @extend .separator;
    background-image: linear-gradient(to right, rgba($MessageBoxFailBackgroundColor, .6) 23%, rgba(255, 255, 255, .6) 0%);
}

.separator-payment-cancel {
    @extend .separator;
    background-image: linear-gradient(to right, rgba($MessageBoxCancelBackgroundColor, .6) 23%, rgba(255, 255, 255, .6) 0%);
}

.separator-payment-timeout {
    @extend .separator;
    background-image: linear-gradient(to right, rgba($MessageBoxTimeoutBackgroundColor, .6) 23%, rgba(255, 255, 255, .6) 0%);
}
